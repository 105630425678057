.Info > h1 {
    font-size: 1.2em;
    margin-bottom: 0;
    color: #ccc;
}
.Info > p {
    margin-top: 0;
    color: #ccc;
    font-size: 1.1em;
}
.Info {
    background-color: rgba(0, 0, 122, 0.757);
    border-left: 7px solid rgb(0, 0, 122);
    padding: 1em;
    margin-bottom: 1em;
}
 