.Project {
    display: grid;
    grid-template-columns: 15vw 60vw 25vw;
    grid-template-rows: 15vh 0px repeat(8, auto);
    
}
.ImgContainer img {
    width: 23vw
}
.ImgContainer {
    margin: 1em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2em;
}
.ProjectTitle {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    flex-direction: column;

}
.ProjectTitle h1 {
    font-size: 2em;
    font-weight: bold;
    padding-right: 1rem;
    margin-bottom: 0;
}
.ProjectTitle p {
    font-size: 1em;
    margin-block: 0;
}
#overview {
    grid-column: 2;
    grid-row: 3;
    padding-block: 1rem;
    padding-left: 1rem;
    margin-top: 0;
}
#software {
    grid-column: 2;
    grid-row: 4;
    padding-block: 1rem;
    padding-left: 1rem;
    margin-top: 0;
}
#hardware {
    grid-column: 2;
    grid-row: 5;
    padding-block: 1rem;
    padding-left: 1rem;
    margin-top: 0;
}
#playbyplay {
    grid-column: 2;
    grid-row: 6;
    padding-block: 1rem;
    padding-left: 1rem;
    margin-top: 0;
}
.ProjectSubHeading > p {
    font-size: 1.2em;
}

.ProjectImage {
    width: 40%;
    margin: 1em;
}
@media only screen and (max-width: 600px) {

    .Project {
        display: grid;
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: repeat(4, auto);
    }
    .ProjectImage {
        width: 100%;
        margin: 0;
    }
}
