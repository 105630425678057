.NavBarTitle {
    font-size: 1.5em;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    padding-block: 1rem;
    padding-left: 1rem;
    color: #000;
}
.mode_svg * {
    fill: #000;
    stroke: #000;
    transition: 0.1s ease;
}
body.darkmode .mode_svg * {
    fill: #bbb;
    stroke: #bbb;
    transition: 0.1s ease;
}
body.colorful .mode_svg {
    fill: rgb(185, 0, 117);
    transition: 0.1s ease;
}
body.darkmode *.NavBarTitle {
    color: rgb(154, 154, 154);
}
body.colorful *.NavBarTitle {
    color: rgb(183, 128, 0);
}
.NavBar > * {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

a.NavBarLocation {
    font-size: 0.8em;
    font-weight: bold;
    align-self: center;
    padding: 1rem;
    color: #000;
}
body.darkmode a.NavBarLocation {
    color: #ccc;
}
.NavBar {
    top: 0;
    left: 0;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 0.5em;
    position:fixed;
    display: flex;
    flex-direction: row;
    width: 100vw;
}
body.darkmode *.NavBar {
    background-color: #171717;
    border-bottom: 1px solid rgb(62, 62, 62);
    border-top: 1px solid rgb(62, 62, 62);
}
body.colorful *.NavBar {
    background-color: #6a006f;
    border-bottom: 1px solid rgb(220, 0, 70);
    border-top: 1px solid rgb(146, 100, 0);
}
.NavBarItems {
    display: flex;
    flex-direction: row;

}
.NavBarItem {
    font-size: 1.3em;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0.5em;
    padding-inline: 1em;
    align-self: center;

    border: none;
    border-right: #000 1px solid;
    /* transition: ease-in 0.3s; */
}
body.darkmode *.NavBarItem {
    color: rgb(126, 126, 126);
    border-right: rgb(116, 116, 116) 1px solid;
}
body.colorful *.NavBarItem {
    color: rgb(198, 96, 0);
    border-right: rgb(216, 0, 115) 1px solid;
}
.NavBarItem:hover {
    transition: ease-out 0.3s;
    background-color: #cbcbcb;
}

body.darkmode *.NavBarItem:hover {
    background-color: rgb(57, 57, 57);
}

body.colorful *.NavBarItem:hover {
    background-color: rgba(57, 57, 57, 0.1);
}
.LightDarkToggle {
    display: flex;
    flex-direction: row;
    align-items: right;
    flex-grow: 10;
    padding-right: 1rem;
    justify-content: right;
}
.Lightbulb {
    align-self: center;
    width: 4em;
    height: 4em;
}
.MobileMenu {
    display: none;
}
.MobileMenuButton {
    display: none;
}

@media only screen and (max-width: 880px) {
    .NavBarItem {
        display: none;
    }
    a.NavBarLocation {
        display: none;
    }
    .MobileMenuButton {
        display: block;
        background-color: transparent;
        border: none;
        align-self: center;
        margin-bottom: 1.5%;
        padding-inline: 2.5%;
        margin-left: 10%;
    }
    .MobileMenu {
        transition: 0.5s;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: -1000px;
        left: 0;
        width: 100vw;
        margin: 0px;
        padding: 0px;
        background-color: transparent;
    }
    .MobileMenu a {
        width: 100%;
        text-align: center;
        padding: 1rem;
        text-decoration: none;
    }
    .MobileMenuItem {
        transition: 0.5s;
        border-bottom: rgb(144, 144, 144) 1px solid;
        backdrop-filter: blur(4px);
        height: 2em;
        font-size: 1em;
        background-color: rgba(57, 57, 57, 0.7);
        width: 80%;
        align-items: center;
        justify-content: center;
        text-align: center;
        
    }
    body.darkmode .MobileMenuItem {
        border-bottom: rgb(144, 144, 144) 1px solid;
        background-color: rgba(57, 57, 57, 0.7);
      
    }
    .MobileMenuItem {
        border-bottom: rgb(45, 45, 45) 1px solid;
        background-color: rgba(234, 234, 234, 0.7);
      
    }
}
