body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-color: transparent;
}
body.darkmode {
  background-color: rgb(10, 10, 10);
}
body.colorful {
  background-color: rgb(128, 0, 66);
}
* {
  font-family: 'Courier New', Courier, monospace;
}
body.darkmode * {
    color: #ccc;
}
body.colorful * {
    color: rgb(255, 85, 0);
}
* {
    color: black;
}
