.About {
    display: inline-grid;
    grid-template-columns: 15vw 60vw 25vw;
    grid-template-rows: auto auto auto auto;

}

.PageContent {
    grid-column: 2;
    display: flex;
    flex-direction: column;
}
.Blurbs {

    display: flex;
    flex-direction: column;
}

a.Link {
    color: rgb(100, 100, 255);
    padding: 0;
}
body.darkmode a.Link {
    color: rgb(0, 0, 190);
}
body.colorful a.Link {
    color: rgb(108, 0, 138);
}
.right {
    align-self: flex-end;
}
.left {
    align-self: flex-start;
}
.center {
    align-self: center;
}
.AboutBlurb * {
    padding-block: 1rem

}
.AboutBlurb {
    padding-block: 2rem;
    grid-column: 2;
    text-align: center;
    width: 100%;
    font-size: 1.2em;
}
.cols {

    display: flex;
    flex-direction: column;
}
.rows {

    display: flex;
    flex-direction: row;
}
.Image {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .About {
        display: inline-grid;
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: auto auto auto auto;
    
    }
    .ScrollVideo video {
        width: 100%;
        margin: 0%;
        padding: 0;
    }
  }
