
* {
    font-family: 'Courier New', Courier, monospace;
}
body.darkmode * {
    color: #ccc;
}
body.colorful a {
    color: rgb(154, 0, 72);
}
.FireworkCanvas {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.DashedList {
    list-style-type:circle;
}

body.darkmode .GithubIcon {
    fill: #ccc;
}
body.colorful .GithubIcon {
    fill: rgb(178, 65, 0);
}
.GithubIcon {
    fill: black;
}

#master_footer {
    height: 100px;
}
