body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-color: transparent;
}
body.darkmode {
  background-color: rgb(10, 10, 10);
}
body.colorful {
  background-color: rgb(128, 0, 66);
}
* {
  font-family: 'Courier New', Courier, monospace;
}
body.darkmode * {
    color: #ccc;
}
body.colorful * {
    color: rgb(255, 85, 0);
}
* {
    color: black;
}


.Home {
    display: inline-grid;
    grid-template-columns: 15vw 60vw 25vw;
    grid-template-rows: auto auto auto auto;

}
.Home > .center {
    text-align: center;
    margin-inline: 25%;
}
.Home > .left {
    text-align: left;
    margin-right: 50%;
}
.Home > .right {
    text-align: right;
    margin-left: 50%;
}
.Home > p {
    font-size: 1.5em;
    padding-block: 1rem;
    padding-left: 1rem;
    margin-top: 0;
}
.Home > p > button {
    padding: 0.5em;
    margin: 0.5em;
}
.Home > h1 {
    margin-bottom: 0;
}
.Home > * {
    grid-column: 2;
}


p > button {
    border-radius: 1rem;
    padding: 0.3rem;
}
body.darkmode p > button {
    color: #ccc;
    background-color: #171717;
}

body.colorful p > button {
    background-color: #d92800;
}

@media only screen and (max-width: 600px) {

    .Home {
        display: inline-grid;
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: auto auto auto auto;
    
    }

    .Home > .center {
        text-align: center;
        margin-inline: 10%;
        padding-inline: 1rem;
        padding-block: 1em;
    }
    .Home > .left {
        text-align: left;
        margin-right: 20%;
        padding-inline: 1rem;
        padding-block: 1em;
    }
    .Home > .right {
        text-align: right;
        margin-left: 20%;
        padding-inline: 1rem;
        padding-block: 1em;
    }
    .Home > h1 {
        margin-right: 0%;
    }
}

.PostContainer {
    grid-row: 3;
    grid-column: 2;
    
    padding-bottom: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    grid-row-gap: 3em;

    row-gap: 3em;
}
.PostTitle * {
    padding-bottom: 0;
    margin-block: 0;
}
.Post {
    padding-bottom: 3em;
    padding-right: 3em;
    padding-left: 3em;
    display: flex;
    flex-direction: column;
    margin-right: 0;
    width: 40%
}
.Post img {
    width: 30vw;
}

@media only screen and (max-width: 600px) {
    .PostTitle h4 {
        font-size: 0.8em;
        color: gray;
    }
    .Post img {
        width: 80vw;
    }
    .Post {

        width: 100%;
        padding-left: 0em;
        padding-right: 0em;
    }
    .Post * {
        padding-bottom: 1em;
        margin-block: 0;
    }
  }

.Blog {
    display: grid;
    grid-template-columns: 15vw 70vw 15vw;
    grid-template-rows: 20vh repeat(8, auto);
}
.BlogTitle {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    flex-direction: column;
}
.BlogTitle h1 {
    padding-right: 1rem;
    margin-bottom: 0;
}
.BlogTitle p {
    margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
    .Blog {
        display: grid;
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: 20vh repeat(8, auto);
    }
    .BlogTitle p {
        margin-bottom: 0;
    }
    
}

.ProjectTab {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    padding-bottom: 2em;
    grid-column: 2;
    grid-row: auto;
}
body.darkmode .ProjectTabDate {
    color: #ccc;
}
body.darkmode .ProjectTabLink {
    color: #ccc;
}
body.colorful .ProjectTabDate {
    color: rgb(208, 159, 0);
}
body.colorful .ProjectTabLink {
    color: rgb(158, 119, 0);
}
.ProjectTabDate {
    font-size: 0.8em;
    color: #111;
    margin-block: 0;
    margin-inline: 0;
    padding-block: 0;
    padding-inline: 0;
}
.ProjectTabTitle {
    font-size: 1.3em;
    font-weight: bold;
    margin-block: 0;
    padding-right: 1rem;
}
.ProjectTabLink {
    color: grey;
    padding: 0;
    justify-self: start;
    align-self: center;
    cursor: pointer;
    padding-inline: 1rem;
}
.GithubLink {
    padding: 0;
    justify-self: center;
    align-self: center;
    cursor: pointer;
    margin-top: 0.4em;
    padding-inline: 1rem;
}
.ProjectTabSkills {
    font-weight: bold;
    font-size: 0.9em;
    margin-top: -0.5em;
    padding-block: 0;
}
.ProjectTabTextDivider {
    font-size: 2em;
    font-weight: bolder;
    margin-block: 0;
}
.ProjectTabTitleArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-block: 0;
}
.ProjectTabDescription {
    font-size: 1.1em;
    margin-block: 0;
    padding-block: 0;
    margin-right: 50%;
}

@media only screen and (max-width: 880px) {

    .ProjectTabSkills {
        font-weight: bold;
        font-size: 0.9em;
        margin-top: 0em;
        padding-block: 0;
    }
    .ProjectTabDescription {
        font-size: 1.1em;
        margin-block: 0;
        padding-block: 0;
        margin-right: 0;
    }
    .ProjectTabTitle {
        width: -webkit-min-content;
        width: min-content;
    }
    .ProjectTabLink {
        white-space: nowrap;
    }
}


* {
    font-family: 'Courier New', Courier, monospace;
}
body.darkmode * {
    color: #ccc;
}
body.colorful a {
    color: rgb(154, 0, 72);
}
.FireworkCanvas {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.DashedList {
    list-style-type:circle;
}

body.darkmode .GithubIcon {
    fill: #ccc;
}
body.colorful .GithubIcon {
    fill: rgb(178, 65, 0);
}
.GithubIcon {
    fill: black;
}

#master_footer {
    height: 100px;
}

.Projects {
    display: grid;
    grid-template-columns: 15vw 60vw 25vw;
    grid-template-rows: repeat(4, 1fr);
}
.ProjectsTitle {
    grid-column: 2;
    grid-row: 1;
    font-weight: bold;
    grid-column: 2;
}

@media only screen and (max-width: 600px) {

    .Projects {
        display: grid;
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: repeat(4, 1fr);
    }
}
    


.NavBarTitle {
    font-size: 1.5em;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    padding-block: 1rem;
    padding-left: 1rem;
    color: #000;
}
.mode_svg * {
    fill: #000;
    stroke: #000;
    transition: 0.1s ease;
}
body.darkmode .mode_svg * {
    fill: #bbb;
    stroke: #bbb;
    transition: 0.1s ease;
}
body.colorful .mode_svg {
    fill: rgb(185, 0, 117);
    transition: 0.1s ease;
}
body.darkmode *.NavBarTitle {
    color: rgb(154, 154, 154);
}
body.colorful *.NavBarTitle {
    color: rgb(183, 128, 0);
}
.NavBar > * {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

a.NavBarLocation {
    font-size: 0.8em;
    font-weight: bold;
    align-self: center;
    padding: 1rem;
    color: #000;
}
body.darkmode a.NavBarLocation {
    color: #ccc;
}
.NavBar {
    top: 0;
    left: 0;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 0.5em;
    position:fixed;
    display: flex;
    flex-direction: row;
    width: 100vw;
}
body.darkmode *.NavBar {
    background-color: #171717;
    border-bottom: 1px solid rgb(62, 62, 62);
    border-top: 1px solid rgb(62, 62, 62);
}
body.colorful *.NavBar {
    background-color: #6a006f;
    border-bottom: 1px solid rgb(220, 0, 70);
    border-top: 1px solid rgb(146, 100, 0);
}
.NavBarItems {
    display: flex;
    flex-direction: row;

}
.NavBarItem {
    font-size: 1.3em;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0.5em;
    padding-inline: 1em;
    align-self: center;

    border: none;
    border-right: #000 1px solid;
    /* transition: ease-in 0.3s; */
}
body.darkmode *.NavBarItem {
    color: rgb(126, 126, 126);
    border-right: rgb(116, 116, 116) 1px solid;
}
body.colorful *.NavBarItem {
    color: rgb(198, 96, 0);
    border-right: rgb(216, 0, 115) 1px solid;
}
.NavBarItem:hover {
    transition: ease-out 0.3s;
    background-color: #cbcbcb;
}

body.darkmode *.NavBarItem:hover {
    background-color: rgb(57, 57, 57);
}

body.colorful *.NavBarItem:hover {
    background-color: rgba(57, 57, 57, 0.1);
}
.LightDarkToggle {
    display: flex;
    flex-direction: row;
    align-items: right;
    flex-grow: 10;
    padding-right: 1rem;
    justify-content: right;
}
.Lightbulb {
    align-self: center;
    width: 4em;
    height: 4em;
}
.MobileMenu {
    display: none;
}
.MobileMenuButton {
    display: none;
}

@media only screen and (max-width: 880px) {
    .NavBarItem {
        display: none;
    }
    a.NavBarLocation {
        display: none;
    }
    .MobileMenuButton {
        display: block;
        background-color: transparent;
        border: none;
        align-self: center;
        margin-bottom: 1.5%;
        padding-inline: 2.5%;
        margin-left: 10%;
    }
    .MobileMenu {
        transition: 0.5s;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: -1000px;
        left: 0;
        width: 100vw;
        margin: 0px;
        padding: 0px;
        background-color: transparent;
    }
    .MobileMenu a {
        width: 100%;
        text-align: center;
        padding: 1rem;
        text-decoration: none;
    }
    .MobileMenuItem {
        transition: 0.5s;
        border-bottom: rgb(144, 144, 144) 1px solid;
        -webkit-backdrop-filter: blur(4px);
                backdrop-filter: blur(4px);
        height: 2em;
        font-size: 1em;
        background-color: rgba(57, 57, 57, 0.7);
        width: 80%;
        align-items: center;
        justify-content: center;
        text-align: center;
        
    }
    body.darkmode .MobileMenuItem {
        border-bottom: rgb(144, 144, 144) 1px solid;
        background-color: rgba(57, 57, 57, 0.7);
      
    }
    .MobileMenuItem {
        border-bottom: rgb(45, 45, 45) 1px solid;
        background-color: rgba(234, 234, 234, 0.7);
      
    }
}

.About {
    display: inline-grid;
    grid-template-columns: 15vw 60vw 25vw;
    grid-template-rows: auto auto auto auto;

}

.PageContent {
    grid-column: 2;
    display: flex;
    flex-direction: column;
}
.Blurbs {

    display: flex;
    flex-direction: column;
}

a.Link {
    color: rgb(100, 100, 255);
    padding: 0;
}
body.darkmode a.Link {
    color: rgb(0, 0, 190);
}
body.colorful a.Link {
    color: rgb(108, 0, 138);
}
.right {
    align-self: flex-end;
}
.left {
    align-self: flex-start;
}
.center {
    align-self: center;
}
.AboutBlurb * {
    padding-block: 1rem

}
.AboutBlurb {
    padding-block: 2rem;
    grid-column: 2;
    text-align: center;
    width: 100%;
    font-size: 1.2em;
}
.cols {

    display: flex;
    flex-direction: column;
}
.rows {

    display: flex;
    flex-direction: row;
}
.Image {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .About {
        display: inline-grid;
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: auto auto auto auto;
    
    }
    .ScrollVideo video {
        width: 100%;
        margin: 0%;
        padding: 0;
    }
  }

.Project {
    display: grid;
    grid-template-columns: 15vw 60vw 25vw;
    grid-template-rows: 15vh 0px repeat(8, auto);
    
}
.ImgContainer img {
    width: 23vw
}
.ImgContainer {
    margin: 1em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    grid-gap: 2em;
    gap: 2em;
}
.ProjectTitle {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    flex-direction: column;

}
.ProjectTitle h1 {
    font-size: 2em;
    font-weight: bold;
    padding-right: 1rem;
    margin-bottom: 0;
}
.ProjectTitle p {
    font-size: 1em;
    margin-block: 0;
}
#overview {
    grid-column: 2;
    grid-row: 3;
    padding-block: 1rem;
    padding-left: 1rem;
    margin-top: 0;
}
#software {
    grid-column: 2;
    grid-row: 4;
    padding-block: 1rem;
    padding-left: 1rem;
    margin-top: 0;
}
#hardware {
    grid-column: 2;
    grid-row: 5;
    padding-block: 1rem;
    padding-left: 1rem;
    margin-top: 0;
}
#playbyplay {
    grid-column: 2;
    grid-row: 6;
    padding-block: 1rem;
    padding-left: 1rem;
    margin-top: 0;
}
.ProjectSubHeading > p {
    font-size: 1.2em;
}

.ProjectImage {
    width: 40%;
    margin: 1em;
}
@media only screen and (max-width: 600px) {

    .Project {
        display: grid;
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: repeat(4, auto);
    }
    .ProjectImage {
        width: 100%;
        margin: 0;
    }
}

.Info > h1 {
    font-size: 1.2em;
    margin-bottom: 0;
    color: #ccc;
}
.Info > p {
    margin-top: 0;
    color: #ccc;
    font-size: 1.1em;
}
.Info {
    background-color: rgba(0, 0, 122, 0.757);
    border-left: 7px solid rgb(0, 0, 122);
    padding: 1em;
    margin-bottom: 1em;
}
 
.PostPage {
    display: grid;
    grid-template-columns: 15vw 60vw 25vw;
    grid-template-rows: repeat(8, auto);

}
.PostArea {
    grid-column: 2;
}
.PostTitle * {
    padding-bottom: 0;
    margin-block: 0;
}
.PostTitle h1 {
    height: 3em;
}
.PostTitle h4 {
    height: 3em;
}
.Post {
    display: flex;
    flex-direction: column;
}
.PostArea img {
    padding-left: 10vw;
    width: 40vw;
    height: auto;
} 
.PostArea a {
    color: blue;
    padding: 0;
}
body.darkmode .stlviewer {
    border: gray 4px solid;
}
body.colorful .stlviewer {
    border: rgb(199, 106, 0) 4px solid;
}
.stlviewer {
    border: gainsboro 4px solid;
}

@media only screen and (max-width: 600px) {
    .PostPage {
        display: grid;
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: repeat(8, auto);
    
    }
    .PostTitle h1 {
        height: auto;
    }
    .PostTitle h4 {
        height: auto;
    }
}

.Page {
    display: grid;
    grid-template-columns: 15vw 60vw 25vw;
    grid-template-rows: 20vh repeat(8, auto);
}
.PageContent {
    grid-column: 2;
}

.ContactMethod > h1 {

}
.ContactMethod {
    margin-bottom: 3em;
}
.ContactMethod > p {
    margin: 0.4em;
    margin-left: 2em;
}

