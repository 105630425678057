.Projects {
    display: grid;
    grid-template-columns: 15vw 60vw 25vw;
    grid-template-rows: repeat(4, 1fr);
}
.ProjectsTitle {
    grid-column: 2;
    grid-row: 1;
    font-weight: bold;
    grid-column: 2;
}

@media only screen and (max-width: 600px) {

    .Projects {
        display: grid;
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: repeat(4, 1fr);
    }
}
    

