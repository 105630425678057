.ProjectTab {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    padding-bottom: 2em;
    grid-column: 2;
    grid-row: auto;
}
body.darkmode .ProjectTabDate {
    color: #ccc;
}
body.darkmode .ProjectTabLink {
    color: #ccc;
}
body.colorful .ProjectTabDate {
    color: rgb(208, 159, 0);
}
body.colorful .ProjectTabLink {
    color: rgb(158, 119, 0);
}
.ProjectTabDate {
    font-size: 0.8em;
    color: #111;
    margin-block: 0;
    margin-inline: 0;
    padding-block: 0;
    padding-inline: 0;
}
.ProjectTabTitle {
    font-size: 1.3em;
    font-weight: bold;
    margin-block: 0;
    padding-right: 1rem;
}
.ProjectTabLink {
    color: grey;
    padding: 0;
    justify-self: start;
    align-self: center;
    cursor: pointer;
    padding-inline: 1rem;
}
.GithubLink {
    padding: 0;
    justify-self: center;
    align-self: center;
    cursor: pointer;
    margin-top: 0.4em;
    padding-inline: 1rem;
}
.ProjectTabSkills {
    font-weight: bold;
    font-size: 0.9em;
    margin-top: -0.5em;
    padding-block: 0;
}
.ProjectTabTextDivider {
    font-size: 2em;
    font-weight: bolder;
    margin-block: 0;
}
.ProjectTabTitleArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-block: 0;
}
.ProjectTabDescription {
    font-size: 1.1em;
    margin-block: 0;
    padding-block: 0;
    margin-right: 50%;
}

@media only screen and (max-width: 880px) {

    .ProjectTabSkills {
        font-weight: bold;
        font-size: 0.9em;
        margin-top: 0em;
        padding-block: 0;
    }
    .ProjectTabDescription {
        font-size: 1.1em;
        margin-block: 0;
        padding-block: 0;
        margin-right: 0;
    }
    .ProjectTabTitle {
        width: min-content;
    }
    .ProjectTabLink {
        white-space: nowrap;
    }
}
