
.Home {
    display: inline-grid;
    grid-template-columns: 15vw 60vw 25vw;
    grid-template-rows: auto auto auto auto;

}
.Home > .center {
    text-align: center;
    margin-inline: 25%;
}
.Home > .left {
    text-align: left;
    margin-right: 50%;
}
.Home > .right {
    text-align: right;
    margin-left: 50%;
}
.Home > p {
    font-size: 1.5em;
    padding-block: 1rem;
    padding-left: 1rem;
    margin-top: 0;
}
.Home > p > button {
    padding: 0.5em;
    margin: 0.5em;
}
.Home > h1 {
    margin-bottom: 0;
}
.Home > * {
    grid-column: 2;
}


p > button {
    border-radius: 1rem;
    padding: 0.3rem;
}
body.darkmode p > button {
    color: #ccc;
    background-color: #171717;
}

body.colorful p > button {
    background-color: #d92800;
}

@media only screen and (max-width: 600px) {

    .Home {
        display: inline-grid;
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: auto auto auto auto;
    
    }

    .Home > .center {
        text-align: center;
        margin-inline: 10%;
        padding-inline: 1rem;
        padding-block: 1em;
    }
    .Home > .left {
        text-align: left;
        margin-right: 20%;
        padding-inline: 1rem;
        padding-block: 1em;
    }
    .Home > .right {
        text-align: right;
        margin-left: 20%;
        padding-inline: 1rem;
        padding-block: 1em;
    }
    .Home > h1 {
        margin-right: 0%;
    }
}
