.PostContainer {
    grid-row: 3;
    grid-column: 2;
    
    padding-bottom: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    row-gap: 3em;
}
.PostTitle * {
    padding-bottom: 0;
    margin-block: 0;
}
.Post {
    padding-bottom: 3em;
    padding-right: 3em;
    padding-left: 3em;
    display: flex;
    flex-direction: column;
    margin-right: 0;
    width: 40%
}
.Post img {
    width: 30vw;
}

@media only screen and (max-width: 600px) {
    .PostTitle h4 {
        font-size: 0.8em;
        color: gray;
    }
    .Post img {
        width: 80vw;
    }
    .Post {

        width: 100%;
        padding-left: 0em;
        padding-right: 0em;
    }
    .Post * {
        padding-bottom: 1em;
        margin-block: 0;
    }
  }
