.Blog {
    display: grid;
    grid-template-columns: 15vw 70vw 15vw;
    grid-template-rows: 20vh repeat(8, auto);
}
.BlogTitle {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    flex-direction: column;
}
.BlogTitle h1 {
    padding-right: 1rem;
    margin-bottom: 0;
}
.BlogTitle p {
    margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
    .Blog {
        display: grid;
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: 20vh repeat(8, auto);
    }
    .BlogTitle p {
        margin-bottom: 0;
    }
    
}
