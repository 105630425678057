.PostPage {
    display: grid;
    grid-template-columns: 15vw 60vw 25vw;
    grid-template-rows: repeat(8, auto);

}
.PostArea {
    grid-column: 2;
}
.PostTitle * {
    padding-bottom: 0;
    margin-block: 0;
}
.PostTitle h1 {
    height: 3em;
}
.PostTitle h4 {
    height: 3em;
}
.Post {
    display: flex;
    flex-direction: column;
}
.PostArea img {
    padding-left: 10vw;
    width: 40vw;
    height: auto;
} 
.PostArea a {
    color: blue;
    padding: 0;
}
body.darkmode .stlviewer {
    border: gray 4px solid;
}
body.colorful .stlviewer {
    border: rgb(199, 106, 0) 4px solid;
}
.stlviewer {
    border: gainsboro 4px solid;
}

@media only screen and (max-width: 600px) {
    .PostPage {
        display: grid;
        grid-template-columns: 5vw 90vw 5vw;
        grid-template-rows: repeat(8, auto);
    
    }
    .PostTitle h1 {
        height: auto;
    }
    .PostTitle h4 {
        height: auto;
    }
}
